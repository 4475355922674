.servicestatus {
  position: absolute;
  top: 5rem;
  left: 1.5rem;
  width: 22rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: $color-shades-black;
  border-left: 0.5rem solid $color-hues-yellow;
  border-radius: 0.5rem;
  overflow: hidden;
  z-index: 9999;
  color: $color-shades-white;

  &.hidden {
    display: none;
  }

  &-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    width: 100%;
  }

  &-content {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1.5rem;
    gap: 1rem;

    li {
      padding-bottom: 1rem;
      border-bottom: 1px solid $color-shades-lightBlack;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
