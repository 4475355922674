  .tag {
    padding: 0.25rem 0.375rem;
    background: $color-shades-white;
    border-radius: 0.25rem;
    border: 1px solid $color-shades-lightGray;
    font-size: 0.875rem;
    font-family: $font-family-secondary;
    font-weight: 600;
    display: flex;
    align-items: center;
    color: $color-shades-lightBlack;
  
  }