.input__upload {
  width: 100%;

  input {
    display: none;
  }

  button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    gap: 0.5rem;
    width: 100%;
    height: 5rem;
    background: $color-shades-white;
    border: 0.125rem dashed $color-shades-darkGray;
    border-radius: 0.25rem;
    font-family: "IBM Plex Sans";
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.063rem;
    color: $color-shades-black;
  }
}

.input {
  position: relative;
  width: 100%;

  &.error {
    input {
      border-color: $color-flag-notify;
    }
    label,
    select,
    input {
      color: $color-flag-notify;
    }
  }
  label,
  select {
    display: block;
    color: $color-shades-lightBlack;
  }

  label {
    font-family: $font-family-primary;
    font-weight: 800;
    font-size: 0.75rem;
    line-height: 1.5rem;

    @include DS {
      font-size: 13px;
      line-height: 16px;
    }
  }

  input {
    width: 100%;
    font-family: $font-family-secondary;
    border: 1px solid $color-shades-lightBlack;
    margin-top: 0.25rem;
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    line-height: 1.5rem;
    height: 2.5rem;
  }

  .password-visibility {
    display: flex;
    position: absolute;
    right: 0;
    top: 1.75rem;
    margin: 0.5rem;
    cursor: pointer;

    @include TL {
      top: 1.25rem;
    }
    
    svg {
      width: 1.5rem;
      height: 1.5rem;
      margin: 0;
  
      &.d-none {
        display: none;
      }
    }
  }

  &.double {
    display: flex;

    &-left,
    &-left input {
      width: 64px;
      margin-right: 7px;

      @include DS {
        margin-right: 12px;
      }
    }

    &-right {
      width: 100%;
    }
  }
}
