// Default style

.layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(var(--vh) * 100);
  overflow: hidden;

  @include TL {
    flex-direction: row;
  }

  .main {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: $color-shades-lightGray;

    &-top,
    &-bottom {
      width: 100%;
      left: 0;
      position: absolute;
      transition: 0.5s;
      pointer-events: none;
      touch-action: none;
      z-index: 99;
    }

    &-top {
      z-index: 999;
      top: 0;
      padding: 0.75rem 1.5rem;

      &.searching {
        .header__brand,
        .header__actions-mobile {
          display: none;
          @include TP {
            display: flex;
          }
        }
      }
    }

    &-bottom {
      display: flex;
      flex-direction: column;
      bottom: 0;
      padding: 0 1.5rem;
      gap: 1rem;
      pointer-events: none;
    }
  }

  .aside {
    width: 0;
    height: 0;
  }
}

// Active aside panel style
.layout.aside-active {
  flex-direction: column;
  @include TL {
    flex-direction: row;
  }
  .main {
    width: 100%;
    height: calc(var(--vh) * 20);
    z-index: 1;

    @include TP {
      height: calc(var(--vh) * 25);
    }

    @include TL {
      flex-direction: row;
      height: calc(var(--vh) * 100);
      width: calc(100% - 45rem);
    }

    &-bottom {
      display: none;

      @include TP {
        display: flex;
      }
    }
  }

  .aside {
    width: 100%;
    height: calc(var(--vh) * 80);
    position: relative;
    z-index: 2;

    @include TP {
      height: calc(var(--vh) * 75);
    }

    @include TL {
      height: calc(var(--vh) * 100);
      width: 45rem;
      margin-right: -45rem;
    }

    &.fullscreen {
      position: initial;
    }
  }
}

// Active aside and info panel style
.layout.aside-active.panel-info-active {
  .main {
    @include TL {
      width: calc(100% - 45rem - 25rem);
    }

    &-bottom {
      display: none;

      @include TP {
        display: flex;
      }
    }
  }

  .aside {
    display: flex;
    width: 100%;
    @include TP {
      width: calc(45rem + 25rem);
    }
  }

  .panel {
    width: 100%;
    @include TP {
      width: 45rem;
    }
  }

  .panel__info {
    width: 100%;
    position: absolute;
    height: 100%;
    @include TP {
      width: 25rem;
      height: initial;
      position: initial;
    }
  }
}
