.footer {
  display: flex;
  flex-direction: row;
  font-family: $font-family-secondary;
  color: $color-shades-white;
  opacity: 0.8;
  justify-content: center;
  @include TP {
    justify-content: space-between;
  }
  
  &-left,
  &-right {
    display: flex;
    flex-direction: row;
    gap: 0.125rem;
    overflow: hidden;
    pointer-events: all;
  }

  &-left { 
    display: none;
    @include TP {
      display: flex;
    }
  }

  span,
  a {
    font-size: 0.625rem;
    line-height: 0.75rem;
  }

  a {
    font-weight: $font-weight-medium;
    color: $color-shades-white;
  }

  hr {
    border: 1px solid $color-shades-lightBlack;
    height: 80%;
  }

  button {
    display: flex;
    gap: 0.25rem;
    color: $color-shades-white;
    padding: 0;
    border: none;
    cursor: pointer;
  }


  &__coordinates,
  &__surveydate,
  &__scaleline,
  &__legal {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-shades-black;
    padding: 0.125rem 0.5rem;
    gap: 0.5rem;
    user-select: none;
    pointer-events: all;

    svg {
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  &__scaleline {
    position: relative;
    padding: 0;
    border-top: 1px solid $color-shades-white;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      width: 1px;
      height: 50%;
      background-color: $color-shades-white;
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }

    .ol-scale-line {
      &-inner {
        font-size: 0.625rem;
        line-height: 0.75rem;
        font-weight: $font-weight-medium;
        text-align: center;
      }
    }
  }
}
