.upload-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  background: $color-shades-white;
  border: 1px solid $color-shades-darkGray;
  border-radius: 0.25rem;

  &__name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.75rem;

    h6 {
      font-family: "IBM Plex Sans";
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.063rem;
      color: $color-shades-black;
    }
  }
  &__delete {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    border-left: 1px solid $color-shades-darkGray;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}
