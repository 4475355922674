select {
  display: block;
  width: 100%;
  color: $color-shades-black;
  border: 1px solid $color-shades-lightBlack;
  padding: 0.5rem 0.75rem;
  font-family: $font-family-secondary;
  appearance: none;
  cursor: pointer;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  background-image: linear-gradient(
      45deg,
      transparent 50%,
      $color-shades-black 50%
    ),
    linear-gradient(135deg, $color-shades-black 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em),
    calc(100% - 14px) calc(1em), calc(100% - 2.5em) 0.5em;
  background-size: 0.5rem 0.5rem, 0.5rem 0.5rem, 1px 1.5rem;
  background-repeat: no-repeat;
  height: 2.5rem;
}
