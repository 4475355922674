.panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: $color-shades-white;
  padding: 1.5rem;
  overflow-y: auto;
  gap: 1.5rem;

  @include TL {
    overflow-y: hidden;
    padding: 1.5rem 2rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $color-shades-lightGray;
    gap: 1rem;

    &__content {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }

    &__coordinates {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      padding: 0.25rem 0.5rem;
      width: fit-content;
      border: 1px solid $color-shades-darkGray;
      border-radius: 0.25rem;
      cursor: pointer;

      .span--bold {
        border-right: 1px solid $color-shades-darkGray;
        padding-right: 0.5rem;
      }

      svg {
        display: none;
        width: 1rem;
        height: 1rem;
      }

      &:hover {
        background-color: $color-shades-lightGray;

        svg {
          display: block;
        }
      }
    }
  }
  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: fit-content;
    gap: 4rem;

    &.scroll {
      overflow-y: scroll;
      align-items: flex-start;
    }

    &-box {
      &__status {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        @include TP {
          padding: 0 4rem;
        }
        @include DS {
          padding: 0 5rem;
        }

        &-icon {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          width: 6rem;
          height: 6rem;

          &.Accepted {
            background-color: $color-hues-green;
          }
          &.Denied {
            background-color: $color-flag-notify;
          }
          &.Pending {
            background-color: $color-hues-yellow;
          }
          &.trial-ended {
            background-color: $color-shades-lightGray;
          }

          svg {
            width: 4rem;
            height: 4rem;
          }
        }

        &-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          gap: 0.5rem;

          &__comment {
            display: flex;
            flex-direction: column;
            width: 100%;
            border-radius: 0.5rem;
            border: 2px solid $color-shades-lightGray;
            text-align: center;
            overflow: hidden;

            &-message {
              padding: 1rem;
            }

            &-contact {
              width: 100%;
              padding: 0.5rem;
              justify-content: center;
              font-family: $font-family-primary;
              font-weight: $font-weight-semi-bold;
              text-transform: uppercase;
              font-size: 0.875rem;
              line-height: 1.5rem;
              color: $color-shades-black;
              border-top: 2px solid $color-shades-lightGray;
              background-color: $color-shades-white;

              &:hover {
                background-color: $color-shades-lightGray;
              }
            }
          }
        }
      }

      &__request,
      &__request-forest {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;

        @include TP {
          padding: 0 4rem;
        }
        @include DL {
          padding: 0 4rem;
        }

        form {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }

        &__productcard {
          display: flex;
          flex-direction: row;
          gap: 1rem;

          &-thumbnail {
            display: flex;
            min-width: 6rem;
            background-size: cover;
            border: 1px solid $color-shades-lightGray;
            border-radius: 0.25rem;
          }

          &-description {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
          }
        }

        &__total {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }

        &__head {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 1rem;

          p {
            flex: 1;
          }

          svg {
            width: 1.5rem;
            height: 1.5rem;
          }
        }

        &__head {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 1rem;

          p {
            flex: 1;
          }

          svg {
            width: 1.5rem;
            height: 1.5rem;
          }
        }

        &__switch {
          &-buttons {
            display: flex;
            border: 1px solid $color-shades-lightGray;
            border-radius: 0.25rem;
            padding: 0.25rem;
            gap: 0.25rem;

            input {
              position: absolute !important;
              clip: rect(0, 0, 0, 0);
              height: 1px;
              width: 1px;
              border: 0;
              overflow: hidden;
            }

            label {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              width: 50%;
              min-height: 3rem;
              padding: 0;
              color: $color-shades-lightBlack;
            }

            label:hover {
              cursor: pointer;
            }

            input:checked + label {
              background-color: $color-shades-lightGray;
              border: 1px solid $color-shades-black;
              border-radius: 0.125rem;
              color: $color-shades-black;
            }
          }
        }

        &__checkbox {
          &-buttons {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .checkbox-wrapper {
              position: relative;
              border: 1px solid;
              border-radius: 0.125rem;
              border-color: $color-shades-lightGray;

              label {
                cursor: pointer;
              }

              .checkbox-content {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0.5rem 0.75rem;
                min-height: 3rem;
                color: $color-shades-lightBlack;
                gap: 1rem;

                &__icon {
                  position: relative;
                  height: 1.5rem;
                  width: 1.5rem;
                  border-radius: 1rem;
                  border: 2px solid;
                  border-color: $color-shades-lightGray;
                  background-color: $color-shades-white;
                }

                &__label {
                  flex: 1;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap: 0.5rem;

                  h5 {
                    flex: 1;
                    text-align: left;
                  }
                  h6 {
                    width: 6rem;
                    text-align: right;
                    color: $color-shades-black;
                  }
                }
              }

              &.active {
                background-color: $color-shades-lightGray;
                border-color: $color-shades-black;
                color: $color-shades-black;

                .checkbox-content {
                  &__icon {
                    border-color: $color-shades-black;

                    &::after {
                      content: "";
                      display: block;
                      position: absolute;
                      top: calc(0.35rem - 2px);
                      left: calc(0.35rem - 2px);
                      width: 0.8rem;
                      height: 0.8rem;
                      border-radius: 50%;
                      background-color: $color-shades-black;
                    }
                  }
                }
              }

              input[type="radio"] {
                position: absolute;
                top: 0;
                left: 0;
                appearance: none;
                width: 100%;
                height: 100%;
                cursor: pointer;
              }
            }
          }
        }

        &__miniscule {
          text-align: center;
        }
      }

      &__request-forest {
        width: 100%;
        height: 100%;
        form {
          height: 100%;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          min-height: fit-content;
        }
        &__top,
        &__bottom {
          width: 100%;
          hr {
            margin: 0;
            border-color: $color-shades-lightGray;
          }
        }

        &__head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          p {
            font-family: "IBM Plex Sans";
            font-weight: 500;
            line-height: 1rem;
            line-height: 1.125rem;
            color: $color-shades-black;
          }
          svg {
            width: 24px;
            height: 24px;
          }
        }
        &__checkbox {
          &-head {
            margin-top: 1.5rem;
            margin-bottom: 1rem;
            p {
              font-family: "IBM Plex Sans";
              font-weight: 400;
              font-size: 1rem;
              line-height: 1.375rem;
              color: $color-shades-black;
            }
          }
        }
        &__items {
          display: flex;
          margin-bottom: 0.75rem;
          cursor: default;
          user-select: none;

          img {
            border-radius: 0.125rem;
            margin-right: 1rem;
            max-height: 5rem;
          }

          div {
            flex-grow: 1;
          }

          &-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.25rem;

            h4 {
              font-family: "IBM Plex Sans";
              font-weight: 500;
              font-size: 1rem;
              line-height: 1.125rem;
              color: $color-shades-black;
            }

            span {
              padding: 0.25rem 0.5rem;
              gap: 0.5rem;
              border: 1px solid $color-shades-lightBlack;
              border-radius: 0.125rem;
              font-family: "IBM Plex Sans";
              font-weight: 500;
              font-size: 0.625rem;
              line-height: 0.625rem;
              color: $color-shades-lightBlack;
            }
          }
          &:nth-last-child(1) {
            margin-bottom: 0;
          }
        }
        &__total {
          display: flex;
          justify-content: space-between;
          margin: 1rem auto;
          span {
            font-family: "IBM Plex Sans";
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.125rem;
            color: $color-shades-black;
          }
        }
        &__button {
          display: flex;
          gap: 0.5rem;
          margin-bottom: 1rem;
          flex-direction: column;
          @include TP {
            display: row;
          }
        }
        &__miniscule {
          display: flex;
          justify-content: center;
          font-family: "IBM Plex Sans";
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.063rem;
          color: $color-shades-black;
        }
        &__upload-item {
          margin-top: 1rem;
          display: grid;
          gap: 0.5rem;
          max-height: 67vh;
          overflow: auto;
        }
        &-no-data {
          text-align: center;
          max-width: 448px;
          margin: auto;

          &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            margin-bottom: 1rem;
            padding: 1rem;
            width: 6rem;
            height: 6rem;
            background: $color-flag-notify;
            border-radius: 3rem;

            svg {
              width: 4rem;
              height: 4rem;
            }
          }

          p {
            margin-top: 1rem;
            font-family: "IBM Plex Sans";
            font-weight: 400;
            font-size: 1.125rem;
            line-height: 1.563rem;
            text-align: center;
            color: $color-shades-lightBlack;
          }
        }
      }
      &__viewer {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 2rem;

        ul {
          display: flex;
          flex-direction: row;
          gap: 1rem;

          li {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 0.125rem;
          }
        }

        &__model {
          display: flex;
          position: relative;
          border: 1px solid $color-shades-lightGray;
          overflow: hidden;
          height: 18rem;
          @include TP {
            border-radius: 0.25rem;
            height: calc((var(--vh) * 100) - 38rem);
          }
          @include TL {
            min-height: 25rem;
            height: calc((var(--vh) * 100) - 34rem);
          }

          &.empty {
            align-items: center;
            justify-content: center;
            background-color: $color-shades-lightGray;
            padding: 4rem;
            text-align: center;
          }

          &.fullscreen {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            min-height: calc(var(--vh) * 100);
            z-index: 99;
          }
        }

        &__meta {
          width: 100%;

          ul {
            flex-wrap: wrap;

            li {
              width: 50%;
              max-width: calc(50% - 1rem);

              @include TP {
                width: 25%;
                max-width: calc(25% - 0.75rem);
              }
            }
          }
        }

        &__actions {
          display: flex;
          gap: 0.5rem;
        }

        &__qrcode {
          display: none;
        }

        &__footer {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          &__main {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            &__report {
              display: flex;
              flex-direction: row;
              align-items: center;
              color: $color-shades-black;
              gap: 0.25rem;

              svg {
                width: 1rem;
                height: 1rem;
              }
            }

            &__updated {
              display: flex;
              flex-direction: row;
              gap: 0.25rem;
            }
          }

          .span--minuscule {
            padding: 0.5rem;
            background-color: $color-shades-lightGray;
            text-align: center;
            border-radius: 0.25rem;
          }
        }
      }

      &__upload {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        &__files-list {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }

        &__feature-list {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          width: 100%;
        }
      }
    }
  }
  &__bottom {
    background-color: $color-shades-white;
    position: absolute;
    right: 0;
    left: 1.5rem;
    top: 5.5rem;
    height: fit-content;
    width: 22rem;
    border-radius: 0.5rem;
    transition: 0.5s;
    transform: translateX(calc(-22rem - 2rem));
    z-index: 100;

    // Animation
    &.active {
      transform: translateX(0);
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: inherit;
      width: 100%;
      padding: 1rem 1.5rem;

      & > div:first-of-type {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        h4 {
          font-family: "IBM Plex Mono";
          font-weight: 600;
          font-size: 1.125rem;
          line-height: 1.375rem;
          text-transform: uppercase;
          color: $color-shades-black;
        }
      }

      span {
        font-family: "IBM Plex Sans";
        font-weight: 400;
        color: $color-shades-lightBlack;
      }
      &__city {
        margin-top: 0.5rem;
        span {
          font-size: 1.125rem;
          line-height: 1.563rem;
        }
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        width: 2.5rem;
        height: 2.5rem;
        border: 1px solid $color-shades-lightGray;
        border-radius: 50%;

        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }

    hr {
      margin: 0;
      border-color: $color-shades-lightGray;
    }

    &-body {
      width: 100%;
      padding: 1rem 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1rem;

      &-data {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        &-col {
          display: flex;
          flex-direction: column;
          color: $color-shades-black;

          .span--bold {
            display: inline-flex;
            align-items: center;
            gap: 0.25rem;
          }

          svg {
            margin-left: 0.25rem;
            width: 1rem;
            height: 1rem;
          }

          .status-circle {
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 50%;
            margin-right: 0.25rem;

            &.Available {
              background-color: $color-hues-green;
            }
            &.Archived {
              background-color: $color-shades-lightGray;
            }
            &.Pending {
              background-color: $color-hues-yellow;
            }
            &.Saved {
              background: #ffffff;
              border: 1px solid black;
            }
          }
        }
      }

      &-layers {
        display: flex;
        flex-direction: column;

        &-list {
          display: flex;
          flex-direction: column;
          gap: 0.25rem;

          li {
            display: inline-flex;
            align-items: center;
            gap: 0.25rem;

            &::before {
              content: "";
              display: block;
              width: 0.5rem;
              height: 0.5rem;
              border-radius: 50%;
              background-color: $color-shades-darkGray;
            }
          }
        }
      }

      .saved-request {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;

        button:nth-child(1) {
          padding: 0.5rem;
          background-color: $color-shades-lightGray;
          border: 1px solid $color-shades-black;

          svg {
            width: 1.5rem;
            height: 1.5rem;
          }

          &:hover {
            background-color: $color-shades-white;
            box-shadow: 0 0.25rem 0 transparentize($color-shades-black, 0.8);
          }
        }
      }
    }
  }

  &__content-geo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
    &__checkboxes {
      display: grid;
      align-items: center;
      gap: 0.5rem;
      overflow: auto;
      position: relative;
    }
    &__sum {
      &-action {
        display: flex;
        gap: 16px;
        padding-top: 1rem;
        flex-direction: column;
        @include TL {
          flex-direction: row;
        }

        &-price {
          min-width: 30%;

          h6 {
            text-decoration: line-through;
            font-family: $font-family-secondary;
            font-size: 0.875rem;
          }

          button {
            margin-top: 1px;
            display: flex;
            gap: 2px;
          }

          span {
            font-family: $font-family-secondary;
            font-size: 0.875rem;
            font-weight: 600;
            text-transform: lowercase;
          }

          svg {
            width: 1rem;
            height: 1rem;
          }
        }
      }
    }
    &__order {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    &__details {
      position: absolute;
      bottom: -1.375rem;
      left: -2rem;
      background-color: white;
      width: calc(100% + 4rem);
      padding: 1rem 1.5rem;
      border-top: 1px solid $color-shades-lightGray;
      border-radius: 0.5rem 0.5rem 0 0;
      transform: translateY(100%);
      transition: transform 0.5s ease;

      h4 {
        span {
          font-weight: bold;
          text-transform: lowercase;
        }
      }

      &.open {
        transform: translateY(0);
        z-index: 1002;
      }

      &__comment {
        display: grid;
        gap: 1.25rem;
        padding-top: 2rem;

        textarea {
          padding: 0.75rem;
          color: $color-shades-lightBlack;
          font-family: $font-family-secondary;
          font-size: 0.9375rem;
          font-weight: 400;
          line-height: 1.5rem;
          resize: none;
          height: 5.625rem;

          &:focus,
          &:focus-visible {
            outline: none;
          }
        }
      }

      &__title {
        padding-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h5 {
          color: $color-shades-black;
          font-family: $font-family-secondary;
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.125rem;
        }

        button {
          font-family: $font-family-secondary;
          font-size: 0.875rem;
          font-weight: 600;
          line-height: 1rem;
          text-decoration: underline;
          display: flex;
          align-items: center;
          gap: 0.25rem;

          svg {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }

      &__list {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        &-item {
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          flex-direction: column;
          gap: 0.25rem;

          @include TP {
            align-items: center;
            flex-direction: row;
            gap: 0;
          }

          span {
            color: $color-shades-lightBlack;
            display: inline-flex;
            margin-top: 2px;
          }
          strong {
            font-weight: bold;
            display: inline-flex;
            align-items: center;

            .circle {
              width: 2px;
              height: 2px;
              background-color: $color-shades-lightBlack;
              margin: 0 0.5rem;
              border-radius: 50%;
              display: inline-block;
            }
          }

          h6 {
            width: 100%;
            text-align: flex-end;

            @include TP {
              width: initial;
              text-align: initial;
            }
          }
        }
        hr {
          margin: 0;
        }
      }

      &__discount {
        padding-top: 1rem;
        margin-top: 1rem;
        border-top: 4px solid $color-shades-lightGray;
        &-item {
          display: flex;
          justify-content: space-between;

          align-self: flex-start;
          flex-direction: column;
          gap: 1rem;

          @include TP {
            align-self: center;
            flex-direction: row;
          }

          &__title {
            display: flex;
            align-items: center;
            gap: 0.25rem;

            span {
              display: flex;
              width: 1rem;
              height: 1rem;
              justify-content: center;
              align-items: center;
              background-color: $color-hues-green;
              border-radius: 50%;
            }
          }
        }

        h6 {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;

          @include TP {
            align-items: center;
            justify-content: initial;
          }
        }
      }

      &__sum {
        display: flex;
        justify-content: space-between;
        padding-top: 1rem;
        margin-top: 1rem;
        border-top: 4px solid $color-shades-lightGray;
      }

      &__action {
        padding-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        span {
          padding: 0.25rem 0;
          color: $color-shades-lightBlack;
          font-size: 0.75rem;
          line-height: 120%;
        }
        &-links {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          span {
            width: 0.25rem;
            height: 0.25rem;
            border-radius: 50%;
            background-color: $color-shades-darkGray;
            display: block;
            padding: 0;
          }

          a {
            color: $color-shades-lightBlack;
            font-family: $font-family-secondary;
            font-size: 14px;
            font-weight: 600;
            line-height: 1rem;
            text-decoration-line: underline;
          }
        }

        &.sum {
          padding-top: 0.75rem;
        }
        &-text {
          display: grid;
          gap: 0.5rem;
        }
      }
    }
    &__overlay {
      position: absolute;
      height: 105vh;
      width: calc(100% + 4rem);
      background: #000000;
      z-index: 0;
      top: -10rem;
      left: -2rem;
      animation: fade 1s forwards;
      z-index: 1001;
    }
  }

  &__info {
    background: $color-shades-white;
    box-shadow: inset 2px 0px 0px $color-shades-lightGray;
    z-index: 1;
    display: flex;
    flex-direction: column;

    @include TP {
      z-index: 8;
    }

    &-title {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 1rem 2rem 1.5rem;

      h2 {
        max-width: 80%;
        @include TP {
          max-width: 60%;
        }
      }

      button {
        position: absolute;
        right: 2rem;
        top: 1rem;
        width: 2.75rem;
        height: 2.75rem;
        padding: 0.5rem;
        border: 0.125rem solid $color-shades-lightGray;
        border-radius: 50%;
        background-color: $color-shades-white;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }

    &-image {
      padding-left: 2rem;
      padding-right: 0;

      img {
        width: 100%;
        height: 16.5rem;

        @include TP {
          width: 23rem;
          height: 14rem;
        }
      }
    }

    &-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1rem 2rem;

      &-top {
        &-filters {
          display: flex;
          flex-wrap: wrap;
          gap: 0.3rem;
        }
        &-desc {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          margin-top: 12px;

          p {
            font-size: 0.875rem;
            color: $color-shades-black;
          }
        }
      }

      &-bottom {
        display: none;
        @include TP {
          display: block;
        }
        button {
          width: 100%;
        }
      }
    }
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.4;
  }
}
