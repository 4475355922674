.process {
  display: flex;
  gap: 1rem;

  &__index {
    position: relative;
    span {
      display: grid;
      place-items: center;
      width: 2rem;
      height: 2rem;
      aspect-ratio: 1 / 1;
      border-radius: 50%;
      border: 2px solid var(--black, $color-shades-black);
      color: $color-shades-black;
      font-family: $font-family-primary;
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 120%;
      text-transform: uppercase;
    }
  }

  &:not(:last-child) &__index::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 36px;
    bottom: 0;
    width: 1px;
    background-color: $color-shades-darkGray;
    transform: translateX(-50%);
  }

  &__content {
    display: grid;
    gap: 0.25rem;
    padding: .25rem 0 1rem;
  }
}
