.radio {
  display: inline-block;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid $color-shades-darkGray;
  background-color: $color-shades-white;
  border-radius: 0.75rem;
  cursor: pointer;
  user-select: none;

  &.checked {
    transition: .25s all;
    border-color: $color-shades-black;
    background-color: $color-hues-green;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0.25rem;
      left: 0.25rem;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 0.5rem;
      background-color: $color-shades-black;
    }
  }
}
