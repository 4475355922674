.maptools {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 2rem;
  pointer-events: none;
  
  &-left,
  &-right {
    display: flex;
    flex-direction: column;
    min-width: 6rem;
  }
  &-right {
    align-items: flex-end;
  }

  &-fill {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 0;
  }

  &__zoom {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 0.5rem;
    pointer-events: all;

    button {
      display: flex;
      background-color: $color-shades-white;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 2rem;
      height: 2rem;
      border-radius: 0.25rem;
      border: none;
      cursor: pointer;
      pointer-events: all;
    }
    
    div {
      display: none;
      border-radius: 0.25rem;
      overflow: hidden;

      @include DL {
        display: grid;
      }

      button {
        border-radius: 0;
      }
    }

    hr {
      margin: 0;
      border: 1px solid $color-shades-lightGray;
    }
  }
}
