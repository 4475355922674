.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 3rem;
  position: relative;
  
  &-left {
    display: flex;
    flex-direction: row;
    height: 3rem;
    width: 22rem;
    pointer-events: all;

    &__logo {
      padding: 0.75rem 1rem;
      background-color: $color-shades-white;
      border-radius: 0.25rem 0 0 0.25rem;
      cursor: pointer;

      @include DL {
        padding: 0.75rem 1.5rem;
      }

      svg {
        width: 6.5rem;
        height: 1.5rem;
      }
    }

    &__service {
      display: none;
      position: relative;
      width: 12.5rem;
  
      @include TL {
        display: block;
      }
  
      button,
      &-current,
      &-dropdown {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.75rem 1rem;
        font-family: "IBM Plex Mono";
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 1.375rem;
        min-height: 3rem;
        text-transform: uppercase;
        background-color: $color-shades-black;
        color: $color-shades-white;
        width: 100%;
        
        svg {
          width: 1.5rem;
          height: 1.5rem;
        }
      }

      &-current {
        border-radius: 0 0.25rem 0.25rem 0;
      }
  
      &.active {
        button {
          svg {
            transform: rotate(180deg);
          }
        }
      }
  
      &-dropdown {
        position: absolute;
        padding: 0;
  
        ul {
          width: 100%;
          li {
            button {
              padding: 0.75rem 1rem;
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
  
              svg {
                display: none;
                width: 1rem;
                height: 1rem;
                transform: rotate(0deg) !important;
              }
  
              &:hover {
                background-color: $color-hues-green;
                color: $color-shades-black;
                svg {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }

  &-right {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    height: 3rem;
    pointer-events: all;
    position: absolute;
    right: 0;

    @include TP {
      position: initial;
      right: initial;
    }

    button {
      cursor: pointer;
      border: none;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-shades-white;
    }

    &__search {
      background-color: $color-shades-white;
      border-radius: 0.25rem 0 0 0.25rem;

      form {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        label {
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            cursor: pointer;
            width: 1.5rem;
            height: 1.5rem;
            margin: 0.75rem 1rem;
          }

          input {
            appearance: none;
            all: unset;
            font-family: $font-family-secondary;
            font-weight: $font-weight-regular;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.5rem;
            color: $color-shades-lightBlack;
            width: 0px;
            height: 3rem;
            caret-color: transparent;
            @include TP {
              transition: 0.2s;
            }

            &::placeholder {
              visibility: hidden;
            }

            & + ul + button {
              display: none;
              transition: 0.2s;
              width: 1.5rem;
              height: 1.5rem;
            }
          }
        }
      }

      &.active {
        input {
          caret-color: $color-shades-black;
          width: auto;
          @include TP {
            width: 20rem;
          }

          @include DS {
            width: 25rem;
          }

          &::placeholder {
            visibility: visible;
          }

          & + ul + button {
            display: contents;
          }
        }
      }

      &__list {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100%;
        top: 3rem;
        background-color: $color-shades-white;

        button {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          padding: 0.5rem 1rem;
          background-color: #fff;
          text-decoration: none;
          width: 100%;
          text-align: inherit;
          font-family: $font-family-secondary;
          color: $color-shades-black;
          font-weight: $font-weight-regular;
          font-size: 0.875rem;
          line-height: 1rem;

          & > svg {
            height: 1rem !important;
            width: 1rem !important;
            margin: 0 !important;
            visibility: hidden;
          }

          &:hover {
            background-color: $color-shades-lightGray;

            & > svg {
              visibility: visible;
            }
          }
        }
      }
    }

    &__filter {
      display: flex;
      flex-direction: column;
      background-color: $color-shades-white;
      overflow: hidden;

      &.active {
        min-width: 20rem;
        height: calc(100vh - 5rem);
        border-radius: 0 0 0.5rem 0.5rem;
      }

      &__head {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0.75rem 1rem;
        border-bottom: 1px solid $color-shades-lightGray;
        gap: 0.25rem;
        cursor: pointer;
        user-select: none;

        &__activeNum {
          padding: 0.25rem 0.5rem;
          min-width: 1rem;
          color: $color-shades-white;
          background-color: $color-shades-black;
          font-weight: $font-weight-semi-bold;
          border-radius: 0.5rem;
          animation: pulse-default 2s infinite;

          &.zero {
            background-color: $color-flag-notify;
            animation: pulse-zero 1s infinite;
          }

          @keyframes pulse-default {
            0% { box-shadow: 0 0 0 0 transparentize($color-shades-black, 0.3)}
            70% { box-shadow: 0 0 0 0.5rem transparentize($color-shades-black, 1)}
            100% { box-shadow: 0 0 0 0 transparentize($color-shades-black, 1)}
          }

          @keyframes pulse-zero {
            0% { box-shadow: 0 0 0 0 transparentize($color-flag-notify, 0.3)}
            70% { box-shadow: 0 0 0 0.5rem transparentize($color-flag-notify, 1)}
            100% { box-shadow: 0 0 0 0 transparentize($color-flag-notify, 1)}
          }
        }
      }

      &__panel {
        display: flex;
        flex-direction: column;
        height: 100%;

        &-content {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          height: 3rem;
          padding: 1.5rem 1rem;
          gap: 1.5rem;
          overflow-y: scroll;
          overflow-x: hidden;

          &::-webkit-scrollbar {
            display: none;
          }

          .filterGroup {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            &__head {
              display: flex;
              flex-direction: row;
              align-items: baseline;
              justify-content: space-between;
              gap: 0.5rem;
              user-select: none;

              span {
                cursor: pointer;
              }
            }

            &__content {
              display: flex;
              flex-direction: column;
              gap: 0.5rem;

              &--items {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;

                li {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: flex-start;
                  gap: 0.5rem;
                  cursor: pointer;
                  user-select: none;

                  & > * {
                    pointer-events: none;
                  }

                  & > span {
                    flex: 1;
                  }

                  .itemsCount {
                    display: flex;
                    width: 2rem;
                    height: fit-content;
                    align-items: center;
                    justify-content: center;
                    background-color: $color-shades-lightGray;
                    border-radius: 0.75rem;
                  }
                }
              }

              &--more {
                display: flex;
                align-items: center;
                gap: 0.25rem;
                cursor: pointer;
  
                svg {
                  width: 1.5rem;
                  height: 1.5rem;
                }
  
                &.showless {
                  svg {
                    transform: rotate(-180deg);
                  }
                }
              }
            }
          }
        }

        &-result {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          justify-content: space-between;
          padding: 1rem;
          color: $color-shades-white;
          background-color: $color-shades-black;
          cursor: pointer;

          &.noresults {
            background-color: $color-flag-notify;
          }

          div {
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: transparent;
            color: $color-shades-white;
            gap: 0.25rem;
          }
        }
      }
    }

    &__profile {
      padding: 0.75rem 1rem;
      background-color: $color-shades-white;
      border-radius: 0 0.25rem 0.25rem 0;
      cursor: pointer;
    }
  }
}
