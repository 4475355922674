.dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0.125rem dashed $color-shades-darkGray;
  border-radius: 0.5rem;
  padding: 4rem;
  text-align: center;
  gap: 1rem;
  cursor: pointer;
  transition: .2s;

  &:hover{
    background-color: transparentize($color-hues-green, $amount: 0.8);
    border-color: $color-hues-green;

    .dropzone__icon {
      background-color: $color-shades-white;
    }
  }

  &__icon {
    display: block;
    padding: 2rem;
    border-radius: 3rem;
    background-color: $color-shades-lightGray;

    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
}
