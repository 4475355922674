.tour {
  &-card {
    transform: translate(-50%, -50%);
    z-index: 999;
    box-shadow: 0px 0.25rem 1rem 0px rgba(0, 0, 0, 0.1);
    background-color: $color-shades-white;
    border-radius: 0.5rem;
    width: 15rem;

    @include TP {
      width: 19.5rem;
    }

    @include TL {
      width: 22.5rem;
    }

    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-left: 0.75rem solid transparent;
      border-right: 0.75rem solid transparent;
      border-top: 0.75rem solid $color-shades-white;
    }

    &.bottom {
      &::before {
        bottom: -0.65rem;
        left: 50%;
        margin-left: -0.75rem;
      }
    }

    &.top {
      &::before {
        top: -0.75rem;
        right: 5.5%;
        margin-left: -0.75rem;
        transform: rotateZ(180deg);
      }
    }



    &__header {
      display: flex;
      padding: 0.75rem 1rem;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      border-bottom: 1px solid $color-shades-lightGray;
    }
    &__body {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &-illustration {
        border-radius: 0.25rem;
        height: 7.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 4rem;
          height: 4rem;
        }

        img {
          width: 100%;
          height: 7.5rem;
          object-fit: cover;
          border-radius: 0.25rem;
        }
      }

      &-description {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        h6,
        p {
          text-align: center;
        }
        h6 {
          color: $color-shades-black;
          font-family: $font-family-secondary;
          font-size: 1.125rem;
          line-height: 1.5625rem;
        }

        p {
          color: $color-shades-black;
          font-family: $font-family-secondary;
          font-size: 0.875rem;
          line-height: 1rem;
        }
      }
    }
    &__navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;

      button {
        &.hidden {
          visibility: hidden;
        }
      }

      &-dots {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        align-self: stretch;

        button {
          width: 0.5rem;
          height: 0.5rem;
          border: 1px solid $color-shades-black;
          border-radius: 50%;

          &.active {
            width: 1rem;
            background-color: $color-shades-black;
            border-radius: 0.25rem;
          }
        }
      }
    }
  }
}
