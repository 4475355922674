.infobox {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem 0.75rem;
  width: 100%;
  background-color: transparentize($color-flag-notify, 0.9);
  border-radius: 0.25rem;

  h6 {
    font-family: "IBM Plex Sans";
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.063rem;
    color: $color-flag-notify;
  }
  svg {
    width: 24px;
    height: 24px;
  }
}
