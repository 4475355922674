.modal-layout {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
  opacity: 0.75;
  z-index: 1004;
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1004;
  overflow: auto;

  @include TL {
    overflow: initial;
  }

  &-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1005;
    overflow: scroll;
    height: 100vh;
    min-height: -webkit-fill-available;
    background-color: white;
    
    @include TL {
      background-color: initial;
      overflow: initial;
      height: initial;
    }
  }

  &-content {
    width: 100vw;
    display: flex;
    background-color: $color-shades-white;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0.5rem;
    padding: 3rem 0;

    @include TL {
      width: 44.5625rem;
      padding: 1.5rem 2rem;
      border-radius: 1rem;
      padding: 1.5rem 2rem;
      min-height: 37rem;
      height: initial;
      overflow: initial;
    }

    &.full-height {
      height: 100vh;
      @include TL {
        height: initial;
      }
    }

    &__header {
      text-align: center;
      width: 100%;
      padding-top: 1rem;
      @include TL {
        padding-top: 0;
      }

      button {
        position: absolute;
        right: 2rem;
        top: 3rem;
        display: flex;
        padding: 0.5rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        border-radius: 1.5rem;
        border: 1px solid $color-shades-lightGray;

        @include TL {
          top: 1.5rem;
        }

        &.left {
          left: 2rem;
          right: initial;
        }
      }
    }

    &__body {
      width: 100%;
      min-height: 23.9375rem;
      padding: 1rem 2rem;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      &-form {
        height: 100%;
        display: flex;
        flex-direction: column;
        grid-row-gap: 0.75rem;
        grid-column-gap: 1rem;

        @include TL {
          display: grid;
          grid-template-columns: repeat(2, auto);
        }

        &-errors {
          background-color: rgba(255, 0, 85, 0.2);
          border-radius: 1rem;
          padding: 0.75rem 1rem;
          width: 100%;

          ul {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            padding-left: 1rem;
            list-style: initial;
          }

          li {
            color: #f05;
            font-weight: 600;
            font-family: $font-family-secondary;
            font-size: 0.875rem;
          }
        }

        &__bottom {
          display: flex;
          justify-content: space-between;
          margin-top: 1.5rem;

          a {
            color: $color-shades-black;
            font-family: $font-family-secondary;
            font-weight: 600;
          }
        }
      }

      &-forgotten {
        width: 22rem;
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
      }

      &.login {
        max-width: 22rem;
        margin: 0 auto;
        padding: 0;
        align-items: center;
        flex-direction: initial;
        .modal-content__body-form {
          grid-template-columns: repeat(1, auto);
          width: 100%;

          .input {
            width: 100%;
          }

          button {
            margin-top: 1.25rem;
          }
        }
      }

      p {
        text-align: center;
        color: $color-shades-black;
        font-family: $font-family-secondary;
        font-size: 1.125rem;
        line-height: 1.625rem;
      }

      .button__cta {
        .text {
          border-radius: 0.25rem 0rem 0rem 0.25rem;
          padding: 0.75rem 1.5rem;
          line-height: initial;
        }
        .icon {
          border-radius: 0rem 0.25rem 0.25rem 0rem;
          padding: 0.575rem;
          line-height: initial;
        }
      }

      a {
        text-decoration-line: underline;
        color: $color-shades-black;
      }

      &.center {
        justify-content: center;
        align-items: center;
        gap: 2rem;

        label {
          color: $color-shades-black;
        }
      }

      &-icon {
        span {
          border: 2px solid $color-hues-green;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1.5rem;

          svg {
            height: 4rem;
            width: 4rem;
          }
        }
      }

      &-text {
        max-width: 33.5625rem;
        h2,
        p {
          text-align: center;
        }

        h2 {
          margin-bottom: 0.25rem;
        }
      }

      &.body-accordion,
      &.body-process {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 0;

        p {
          text-align: start;
          color: $color-shades-lightBlack;
          font-family: $font-family-secondary;
          font-size: 0.875rem;
          line-height: 120%;
        }
      }
    }

    form {
      width: 100%;
    }

    &__footer {
      width: calc(100% - 4rem);
      margin: 0 auto;
      padding: 1.5rem 0 1rem;
      border-top: 2px solid $color-shades-lightGray;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      gap: 1rem;

      @include TL {
        gap: 0rem;
        flex-direction: row;
      }

      h6,
      button {
        color: $color-shades-black;
        font-family: $font-family-secondary;
        font-size: 1.125rem;
        line-height: 1.625rem;
      }

      button {
        font-weight: 700;
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }

      &.discount {
        border: none;
        p {
          color: $color-shades-lightBlack;
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }
}
