@use "sass:map";

// COLORS
$colors: (
  hues: (
    "green": hsl(150, 100%, 50%),
    "blue": hsl(190, 100%, 60%),
    "yellow": hsl(60, 90%, 60%),
  ),
  shades: (
    "lightBlack": hsl(240, 10%, 30%),
    "darkGray": hsl(240, 5%, 65%),
    "lightGray": hsl(0, 0%, 93%),
    "white": hsl(0, 0%, 100%),
    "black": hsl(240, 60%, 10%)
  ),
  flag: (
    "notify": hsl(340, 100%, 50%),
  ),
);

$color-hues-green: map.get($colors, "hues", "green");
$color-hues-blue: map.get($colors, "hues", "blue");
$color-hues-yellow: map.get($colors, "hues", "yellow");
$color-shades-lightBlack: map.get($colors, "shades", "lightBlack");
$color-shades-darkGray: map.get($colors, "shades", "darkGray");
$color-shades-lightGray: map.get($colors, "shades", "lightGray");
$color-shades-white: map.get($colors, "shades", "white");
$color-shades-black: map.get($colors, "shades", "black");
$color-flag-notify: map.get($colors, "flag", "notify");


//FONTS

// Font familis
$font-family-primary: "IBM Plex Mono", monospace;
$font-family-secondary: "IBM Plex Sans", sans-serif;

// Weight
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  ms: 0,
  ml: 378px,
  tp: 672px,
  tl: 980px,
  ds: 1200px,
  dl: 1568px,
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  xs: 292px,
  sm: 350px,
  md: 567px,
  lg: 812px,
  xl: 1016px,
  xxl: 1296px,
);

// Grid columns and guttes
//
// Define the number of grid colums and responsive gutters

$grid-columns: 12;
$grid-gutters: (
  xxs: 8px,
  xs: 14px,
  sm: 24px,
  md: 28px,
  lg: 28px,
  xl: 32px,
  xxl: 48px,
);

// Layout and display values
//
// Define the base layout and display values to
// change the alignment of flex items.

$layout-values: flex-start, flex-end, center, space-between, space-around;
$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex;