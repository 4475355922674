.helpPanel {
  display: block;
  width: 25rem;
  height: 100%;
  background-color: $color-shades-lightGray;
  margin-right: -25rem;

  &.active {
    margin-right: 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2rem;
    gap: 2rem;

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;

      button {
        display: flex;
        outline: none;
        border: none;
        padding: 0.5rem;
        border-radius: 50%;
        background-color: $color-shades-white;
        cursor: pointer;

        &:hover {
          background-color: $color-shades-lightGray;
        }

        &:active {
          background-color: $color-shades-black;
    
          svg > path {
            fill: $color-shades-white;
          }
        }
      }
    }

    section {
      display: flex;
      flex-direction: column;
      margin: 0;
      gap: 1rem;

      .head {
        display: flex;
        justify-content: flex-start;
        gap: 0.5rem;
  
        svg {
          width: 1rem;
        }
      }

      h5 {
        margin: 0;
      }

      ul.items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;
        gap: 0.5rem;

        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: $color-shades-white;
          border-radius: 0.25rem;
          flex-basis: 100%;
          padding: 1rem 0;
          gap: 0.5rem;

          .figure {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 5rem;
            height: 3rem;

            .mouse {
              transform-origin: center center;

              .body {
                fill: $color-shades-black;
              }
              .left, .right, .scroll {
                fill: $color-hues-green;
              }
            }

            .key {
              display: block;
              background-color: $color-shades-lightGray;
              padding: 0.5rem 1rem;
              border-radius: 0.4rem;
              border: 1px solid transparentize($color-shades-darkGray, 0.5);
              line-height: 1rem;
              font-size: 0.875rem;
              height: fit-content;
              box-shadow: 0 0.4rem $color-shades-darkGray;
              transform: translateY(-0.2rem);
            }
          }

          span {
            padding: 0 0.5rem;
            text-align: center;
          }

          &.active {
            .figure {
              
              &.dragging {
                .mouse {
                  animation: mouseDrag 1.6s ease 0s infinite;
                  .left {
                    animation: mouseClickAndHold 1.6s ease 0s infinite;
                  }
                  .right {
                    animation: mouseClickAndHold 1.6s ease 0s infinite;
                  }
                }
                @keyframes mouseDrag {
                  30%   { transform: translateX(0) rotate(0) }
                  40%   { transform: translateX(-10%) rotate(-5deg) }
                  50%   { transform: translateY(-5%) }
                  60%   { transform: translateX(10%) rotate(5deg) }
                  70%   { transform: translateX(0) rotate(0) }
                }
                @keyframes mouseClickAndHold {
                  0%   { opacity:0 }
                  9%   { opacity:0 }
                  10%   { opacity:1 }
                  80%   { opacity:1 }
                  81%   { opacity:0 }
                  100%  { opacity:0 }
                }
              }

              &.scrolling {
                .mouse {
                  .scroll {
                    animation: mouseScroll 1.2s ease 0s infinite;
                  }
                }
                @keyframes mouseScroll {
                  0%    { transform: scaleY(1) translateY(0) }
                  50%   { transform: scaleY(0.5) translateY(20%); fill: $color-shades-white }
                  100%  { transform: scaleY(1) translateY(0) }
                }
              }

              &.clicking {
                .mouse {
                  .left {
                    animation: mouseClick 0.8s ease 0s infinite;
                  }
                  .right {
                    animation: mouseClick 0.8s ease 0s infinite;
                  }
                }

                @keyframes mouseClick {
                  0%   { opacity:1 }
                  20%   { opacity:1 }
                  30%   { opacity:0 }
                  100%   { opacity:0 }
                }
              }

              &.shortpress {
                .key {
                  animation: keyShort 0.8s ease 0s infinite;
                }
                @keyframes keyShort {
                  0%    { transform: translateY(-0.2rem); box-shadow: 0 0.4rem $color-shades-darkGray }
                  10%   { transform: translateY(0); box-shadow: 0 0.2rem $color-shades-darkGray; background-color: $color-shades-white }
                  15%   { transform: translateY(0); box-shadow: 0 0.2rem $color-shades-darkGray; background-color: $color-shades-white }
                  20%   { transform: translateY(-0.2rem); box-shadow: 0 0.4rem $color-shades-darkGray }
                }
              }

              &.longpress {
                .key {
                  animation: keyLong 1.6s ease 0s infinite;
                }
                @keyframes keyLong {
                  10%   { transform: translateY(-0.2rem); box-shadow: 0 0.4rem $color-shades-darkGray }
                  15%   { transform: translateY(0); box-shadow: 0 0.2rem $color-shades-darkGray; background-color: $color-shades-white }
                  85%   { transform: translateY(0); box-shadow: 0 0.2rem $color-shades-darkGray; background-color: $color-shades-white }
                  90%   { transform: translateY(-0.2rem); box-shadow: 0 0.4rem $color-shades-darkGray }
                }
              }
            }
          }
        }

        &.halfGrid > li {
          flex-basis: calc(50% - 0.25rem);
        }

        &.thirdGrid > li {
          flex-basis: calc(33.3% - 0.33rem);
        }
      }
    }
  }
}