.toast {
  visibility: hidden;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2rem;
  background-color: $color-shades-white;
  min-height: 3rem;
  min-width: 25rem;
  z-index: 9999;

  &.show {
    visibility: visible;
    animation: fadein 0.5s, fadeout 0.5s 2.75s;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 0.25rem;
      top: -0.25rem;
      width: 100%;
    }

    &.error {
      &::before {
        background-color: $color-flag-notify;
      }
    }
    &.success {
      &::before {
        background-color: $color-hues-green;
      }
    }
  }

  &-content {
    font-family: $font-family-secondary;
    padding: 0.75rem 0.75rem 0.75rem 1rem;
    background: $color-shades-white;
    border: 1px solid $color-shades-lightGray;

    .--row {
      justify-content: space-between;
      align-items: center;
    }

    p {
      font-weight: $font-weight-semi-bold;
      font-size: 1rem;
      line-height: 1.25rem;
      color: $color-shades-black;
    }

    button {
      background-color: transparent;
      border: none;
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}

// Animation
@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 2rem;
    opacity: 1;
  }
}
@keyframes fadeout {
  from {
    bottom: 2rem;
    opacity: 1;
  }
  to {
    bottom: -10rem;
    opacity: 0;
    visibility: hidden;
  }
}
