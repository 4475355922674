.accordion {
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  display: flex;
  padding: 12px 1rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid $color-shades-lightGray;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;

    &-title {
      flex-grow: 1;
    }

    &-right {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      span {
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;
        background: $color-shades-lightGray;
        color: $color-shades-black;
        text-align: center;
        font-weight: 600;
      }
    }

    &-icon {
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      transition: transform 0.3s ease-out;

      &.expanded {
        transform: rotate(180deg);
      }
    }
  }

  &__content {
    overflow: hidden;
    max-height: 0;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    &.expanded {
      max-height: fit-content;
      margin-top: .75rem;
    }

    ul {
      display: grid;
      gap: .5rem;
      
      li {
        display: flex;
        place-items: center;
        color: $color-shades-lightBlack;
        font-family: $font-family-secondary;
        line-height: 140%;

        span {
          display: block;
          margin-inline: 0.5rem;
          width: 0.5rem;
          height: 0.5rem;
          aspect-ratio: 1 / 1;
          border-radius: 50%;
          background-color: $color-shades-darkGray;
        }
      }
    }
  }
}
