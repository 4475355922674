.checkbox {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid $color-shades-darkGray;
  background-color: $color-shades-white;
  border-radius: 0.25rem;
  cursor: pointer;
  user-select: none;
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
  &[aria-checked="true"] {
    background-color: $color-hues-green;
    border-color: $color-shades-black;
  }
  &__geo {
    width: 100%;
    display: flex;
    padding: 0.5rem 0.75rem;
    align-items: center;
    gap: 1rem;
    border: 1px solid white;
    border-radius: 4px;
    align-self: stretch;
    cursor: pointer;

    &-checkbox {
      width: 1.5rem;
      height: 1.5rem;
      padding: 0.25rem;
      border: 1px solid $color-shades-lightGray;
      border-radius: 0.25rem;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 1rem;
        height: 1rem;
      }

      &[aria-checked="true"] {
        background-color: $color-hues-green;
        border-color: $color-hues-green;
      }
    }
    &-text {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__details {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-top: .25rem;

        span {
          color: $color-shades-lightBlack;
        }

        &-circle {
          width: 2px;
          height: 2px;
          background-color: $color-shades-lightBlack;
          border-radius: 50%;
          display: inline-block;
        }
      }
      &__bullet {
        display: flex;
        width: 2px;
        height: 2px;
        border-radius: 2px;
        background: $color-shades-lightBlack;
      }

      &__info {
        height: 2rem;
        width: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &.info {
      border: 1px solid $color-shades-lightGray;
    }
    &.checked {
      border: 1px solid $color-hues-green;
      background-color: rgba(0, 255, 127, 0.2);
      border-radius: 0.25rem;
    }
  }
}

.checkboxForm {
  position: relative;
  display: flex;
  align-items: center;

  &.error {
    .checkboxForm__checkmark {
      border-color: $color-flag-notify;
    }
  }

  input[type="checkbox"] {
    appearance: checkbox !important;
  }

  label,
  input {
    display: flex;
  }

  label {
    font-weight: $font-weight-regular;
    font-size: 0.875rem;
    line-height: 1rem;
    padding-left: 0px;
    display: flex;
    position: relative;
    align-items: center;
    cursor: pointer;
    padding-left: 2rem;
    font-family: $font-family-secondary;
  }

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
    &:checked ~ .checkboxForm__checkmark,
    &:checked ~ div > div .checkbox__domain__checkmark {
      background-color: $color-hues-green;
    }

    &:checked ~ .checkboxForm__checkmark::after,
    &:checked ~ div > div .checkbox__domain__checkmark::after {
      display: block;
    }
  }

  &__checkmark {
    cursor: pointer;
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    background-color: $color-shades-white;
    border: 2px solid $color-shades-black;
    border-radius: 0.25rem;
    top: 50%;
    left: 0.75rem;
    transform: translate(-50%, -50%);

    &:after {
      content: "";
      position: absolute;
      display: none;
    }

    &:after {
      content: "";
      left: 6px;
      top: 1px;
      width: 6px;
      height: 14px;
      border: solid #000;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);

      @include TL {
        left: 7px;
        top: 2px;
        width: 7px;
      }
    }
  }
}

.checkbox-button {
  width: 100%;
  border-radius: 0.25rem;
  border: 1px solid $color-shades-lightGray;
  cursor: pointer;

  label {
    display: flex;
    justify-content: space-between;
    gap: .75rem;
    cursor: pointer;
    padding: 0.5rem 0.625rem;
  }

  &__input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: .75rem;
  }

  &.checked {
    background-color: $color-hues-green;
    border-color: #000;

    span, h5 {
      font-weight: bold;
    }
  }
}
