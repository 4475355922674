figcaption {
  font-size: 0.875rem;
}

// Specific OL Map DOM for displaying
// ortographic image under Model
.ortomap {
  position: fixed;
  width: 1500px;
  height: 1500px;
  visibility: hidden;
}

.model-viewer {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: $color-shades-white;
  touch-action: none;

  &.shrink {
    width: calc(100% - 25rem);
  }

  .fullscreen {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: 1px solid $color-shades-darkGray;
    border-radius: 0.25rem;
    z-index: 9;
    opacity: 0;
    cursor: pointer;

    &__icon {
      position: absolute;
      top: calc(50% - 0.75rem);
      right: calc(50% - 0.75rem);
      outline: none;
      border: none;
      padding: 0.5rem;
      background-color: $color-shades-black;
      border-radius: 0.25rem;
      z-index: 9;
      opacity: 0;
      transform: scale(0.8);
      transition: transform 0.2s ease;
    }

    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparentize($color-shades-black, 0.8);
    }

    &:hover {
      opacity: 1;

      .fullscreen__icon {
        opacity: 1;
        transform: scale(1);
      }
    }

    &.active {
      top: 1.5rem;
      right: 1.5rem;
      width: auto;
      height: auto;
      border: none;
      opacity: 1;

      .fullscreen__icon {
        top: 0;
        right: 0;
        opacity: 1;
        transform: scale(1);
      }

      .fullscreen__overlay {
        display: none;
      }
    }
  }

  .modellistpanel {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 1.5rem;
    left: 1.5rem;
    width: 12rem;
    background-color: $color-shades-white;
    border: 1px solid $color-shades-lightGray;
    border-radius: 0.5rem;
    overflow: hidden;
    z-index: 99;

    &.opened {
      bottom: 4rem;
      width: 22rem;

      .modellistpanel {
        &__header {
          svg {
            transform: rotate(-180deg);
          }
        }

        &__container {
          display: flex;
        }
      }
    }

    ul.meta {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;

      li > span {
        text-align: right;
        width: 4.5rem;
      }
    }

    &__header {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0.75rem 1rem;

      svg {
        width: 1.5rem;
        height: 1.5rem;
        transition: all 0.3s ease;
      }
    }

    &__container {
      display: none;
      flex: 1;
      flex-direction: column;
    }

    &__tab {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid $color-shades-lightGray;

      button {
        flex: 1;
        justify-content: center;
        margin: 0;
        padding: 0.75rem;
        font-family: $font-family-secondary;
        font-weight: $font-weight-semi-bold;
        text-transform: uppercase;
        font-size: 0.875rem;
        line-height: 1rem;

        &.active {
          cursor: default;
          background-color: transparentize($color-hues-green, 0.8);
          border-bottom: 0.25rem solid $color-hues-green;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      height: 3rem;
      align-self: stretch;
      flex-grow: 1;

      &-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $color-shades-lightGray;

        & > * {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0.5rem 1rem;
          gap: 0.25rem;
        }

        button {
          display: flex;
          gap: 0.25rem;
        }

        &__dropdown {
          svg {
            transform: rotateZ(0deg);
            transition: all 0.5s;
            position: relative;
            top: 3px;
          }

          &.active {
            svg {
              transform: rotateZ(-180deg);
            }
          }
        }
      }

      &-groupselection {
        padding: 1rem 0;
        display: block;
        border-bottom: 1px solid $color-shades-lightGray;

        .checkbox {
          &-container {
            padding: 0.375rem 1rem;
            &:hover {
              background-color: $color-shades-lightGray;
            }

            label {
              display: flex;
              gap: 0.5rem;
            }

            span {
              width: 100%;
              cursor: pointer;
            }

            input[type="checkbox"] {
              clip: rect(0 0 0 0);
              clip-path: inset(50%);
              height: 1px;
              overflow: hidden;
              position: absolute;
              white-space: nowrap;
              width: 1px;
            }

            span.checkbox {
              display: inline-block;
              height: 1rem;
              width: 1rem;
              background: 0;
              border: 0;

              svg {
                width: 1rem;
                height: 1rem;
              }
            }
          }
          &-label {
            color: $color-shades-black;

            &.active {
              font-weight: 600;
            }
          }
        }
      }

      &-list {
        align-self: stretch;
        flex-grow: 1;
        padding: 0.5rem 0;
        background-color: transparentize($color-shades-lightGray, 0.5);
        overflow-y: scroll;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          display: none;
        }

        .listpanelitem {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;
          padding: 0.5rem 1rem;
          gap: 0.5rem;
          user-select: none;

          &.hovered {
            background-color: transparentize($color-hues-green, 0.8);
          }

          h5 {
            text-align: left;
            flex-grow: 1;
          }
        }
      }
    }

    &__summary {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 1rem;
      border-top: 2px solid $color-shades-lightGray;
    }
  }

  ul.toolbar {
    display: none;
    position: absolute;
    bottom: 1rem;
    left: calc(50% - 3.75rem);
    list-style: none;
    background-color: $color-shades-black;
    z-index: 99;
    margin: 0;
    padding: 0.25rem;
    border-radius: 0.4rem;
    gap: 0.25rem;
    @include DS {
      display: flex;
    }

    li {
      cursor: pointer;
      margin: 0;
      padding: 0.5rem 1rem;
      border-radius: 0.2rem;
      background-color: $color-shades-black;
      transition: all 0.2s;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }

      &.active,
      &:active {
        background-color: $color-hues-green;
        cursor: default;

        svg > g {
          fill: $color-shades-black;
        }
      }
    }
  }

  .viewbar {
    position: absolute;
    bottom: 1rem;
    left: 1.5rem;
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
    z-index: 99;

    &__type,
    &__lidardetail {
      display: flex;
      flex-direction: column;
      width: 10rem;
      margin: 0;
      padding: 0;
      background-color: $color-shades-white;
      border: 1px solid $color-shades-lightGray;
      border-radius: 0.2rem;
      overflow: hidden;

      &.opened {
        .selected {
          border-bottom: 1px solid $color-shades-lightGray;

          .label {
            svg {
              transform: rotate(-180deg);
            }
          }
        }
        .options {
          max-height: 100vh;
        }
      }

      .selected {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 0.5rem;
        padding: 0.25rem 0.5rem;
        margin: 0;
        outline: none;
        border: none;
        background-color: transparent;
        color: $color-shades-black;
        cursor: pointer;

        &:hover {
          background-color: $color-shades-lightGray;
        }

        svg {
          width: 1.5rem;
          height: 1.5rem;
        }

        .label {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          flex-grow: 1;
          line-height: 1rem;
          gap: 0.2rem;

          span {
            font-weight: 600;
          }

          svg {
            width: 1rem;
            height: 1rem;
            transition: all 0.3s ease 0.3s;
          }
        }
      }

      .options {
        list-style: none;
        padding: 0;
        margin: 0;
        max-height: 0;
        transition: all 0.3s;

        button {
          padding: 0.25rem 0.5rem;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          width: 100%;
          outline: none;
          border: none;
          background-color: transparent;
          color: $color-shades-black;
          cursor: pointer;

          &:hover {
            background-color: $color-shades-lightGray;
          }

          svg {
            width: 1rem;
            height: 1rem;
            margin: 0.25rem;
            opacity: 0;
            transition: opacity 0.2s ease;
          }

          &.active {
            background-color: $color-hues-green;
            cursor: default;
            transition: all 0.2s ease;

            svg {
              opacity: 1;
            }
          }
        }
      }
    }

    .features {
      display: flex;
      flex-direction: row;
      gap: 0.2rem;
      margin: 0;
      padding: 0;

      button {
        display: flex;
        align-items: center;
        outline: none;
        margin: 0;
        padding: 0.25rem 1rem;
        background-color: $color-shades-white;
        border: 1px solid $color-shades-lightGray;
        border-radius: 0.2rem;
        cursor: pointer;

        &:hover {
          background-color: $color-shades-lightGray;
        }

        svg {
          width: 1.5rem;
          height: 1.5rem;
        }

        &.active {
          background-color: $color-hues-green;
        }
      }
    }
  }

  ul.viewactions {
    display: none;
    position: absolute;
    list-style: none;
    justify-content: space-between;
    width: 6rem;
    bottom: 1rem;
    right: 1.5rem;
    margin: 0;
    padding: 0rem;
    z-index: 99;
    @include TP {
      display: flex;
    }
    @include DS {
      width: 5.375rem;
      bottom: 7.5rem;
    }

    li {
      background-color: $color-shades-white;
      border: 1px solid $color-shades-lightGray;
      border-radius: 50%;
      overflow: hidden;

      &:hover {
        background-color: $color-shades-lightGray;
      }

      &.active {
        background-color: $color-hues-green;
      }

      button {
        padding: 0.25rem;
        display: flex;
        align-items: center;
        outline: none;
        border: none;
        background-color: transparent;
        cursor: pointer;

        &:active {
          background-color: $color-shades-black;

          svg > path {
            fill: $color-shades-white;
          }
        }
      }
    }
  }

  .rulerline-text {
    width: auto;
    background-color: $color-shades-white;
    border: 1px solid $color-shades-darkGray;
    border-radius: 1rem;
    animation: 0.2s ease-out 0s 1 fadeIn;
    transition: opacity 0.4s;

    button {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      background: none;
      outline: none;
      border: none;
      font-family: "IBM Plex Sans", sans-serif;
      padding: 0.3rem 0.6rem;
      cursor: pointer;

      .meta {
        display: flex;
        flex-direction: column;
        align-items: center;

        span {
          line-height: 1rem;
          white-space: nowrap;

          &.label {
            font-size: 0.5rem;
            font-weight: 400;
          }

          &.value {
            font-size: 0.75rem;
            font-weight: 600;
          }
        }
      }

      svg {
        display: none;
        width: 1rem;
      }
    }

    &.total {
      background-color: $color-shades-black;
      border-color: $color-shades-black;

      button {
        .meta {
          span {
            color: $color-shades-white;
          }
        }
      }
    }

    &:hover {
      button {
        svg {
          display: block;
        }
      }
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  .cursor-panel {
    width: 14rem;
    background-color: $color-shades-white;
    border-radius: 0.25rem;
    padding: 0.75rem 0 0;
    border: 1px solid $color-shades-lightGray;
    box-shadow: 0 0.25rem 0.5rem transparentize($color-shades-black, 0.8);
    transform: translate3d(-50%, -100%, 0) !important;
    cursor: default;
    user-select: none;

    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem;
        gap: 0.5rem;

        button {
          display: flex;
          outline: none;
          border: none;
          background-color: transparent;
          height: 1.5rem;
          padding: 0.25rem;
          border-radius: 50%;
          cursor: pointer;

          &:hover {
            background-color: $color-shades-lightGray;
          }

          &:active {
            background-color: $color-shades-black;

            svg > path {
              fill: $color-shades-white;
            }
          }

          svg {
            width: 1rem;
            height: 1rem;
          }
        }
      }

      ul.meta {
        list-style: none;
        display: flex;
        flex-direction: column;
        padding: 0 1rem;
        margin: 0;
        gap: 0;

        li {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 0.5rem 0;
          border-bottom: 1px solid $color-shades-lightGray;

          &:last-child {
            border: none;
          }

          span {
            &.label {
              font-weight: 400;
            }

            &.value {
              display: flex;
              align-items: center;
              font-weight: 600;

              .multi-label {
                background-color: $color-shades-lightGray;
                color: $color-shades-lightBlack;
                border-radius: 0.5rem;
                height: 1rem;
                width: 1rem;
                line-height: 1rem;
                margin-left: 0.25rem;
                text-align: center;
                display: none;

                &.active {
                  display: block;
                }
              }
            }
          }
        }
      }

      button.copy {
        display: flex;
        gap: 0.25rem;
        padding: 0.75rem 1rem;
        margin: 0;
        outline: none;
        border: none;
        background-color: transparent;
        border-radius: 0 0 0.25rem 0.25rem;
        cursor: pointer;

        &:hover {
          background-color: $color-shades-lightGray;
        }

        &:active {
          color: $color-shades-white;
          background-color: $color-shades-black;

          svg {
            fill: $color-shades-white;
          }
        }

        svg {
          width: 1rem;
          height: 1rem;
          fill: $color-shades-black;
        }

        span {
          font-family: "IBM Plex Sans", sans-serif;
          font-weight: 600;
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -0.5rem;
      left: calc(50% - 0.25rem);
      width: 0;
      height: 0;
      margin-left: -0.5rem;
      margin-top: -0.5rem;
      border-left: 0.5rem solid transparent;
      border-right: 0.5rem solid transparent;
      border-top: 0.5rem solid $color-shades-white;
    }
  }

  .helpButton {
    position: absolute;
    top: 5rem;
    right: 1.5rem;
    background-color: $color-shades-white;
    border-radius: 0.25rem;
    border: 1px solid $color-shades-lightGray;
    display: none;

    &.inactive {
      @include DS {
        display: block;
      }
    }

    button {
      outline: none;
      border: none;
      background-color: transparent;
      padding: 0.5rem;
      cursor: pointer;

      &:hover {
        background-color: $color-shades-lightGray;
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: $color-shades-black;
      }
    }
  }
}
